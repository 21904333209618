<template>
  <div>
    <div
      :id="toolbarId"
      :class="disabled? 'hide':'ql-toolbar ql-snow ql-toolbar-edit'"
    >
      <b-row class="btn-edit">
        <b-col>
          <button class="ql-bold" />
        </b-col>
        <b-col>
          <button class="ql-italic" />
        </b-col>
        <b-col>
          <button class="ql-link" />
        </b-col>
        <b-col>
          <button
            class="ql-list"
            value="bullet"
          />
        </b-col>
        <b-col>
          <select class="ql-header" />
        </b-col>
        <b-col>
          <select class="ql-color ql-picker ql-color-picker" />
        </b-col>
        <b-col>
          <select class="ql-background ql-picker ql-color-picker" />
        </b-col>
      </b-row>
    </div>
    <quill-editor
      v-model="desc"
      style="margin-bottom: 1rem;"
      :class="disabled? 'hide-toolbar':'quill-custom'"
      :disabled="disabled"
      :options="snowOption"
    />
  </div>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
// import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    quillEditor,
    BRow,
    BCol,
  },
  props: {
    id: {
      type: String,
      default: 'toolbar',
      required: false,
    },
    description: {
      type: String,
      default: '',
    },
    isReadonly: {
      type: Boolean,
    },
    assign: {
      type: Function,
      default: () => this.sendDesc(),
      required: false,
    },
  },
  data() {
    return {
      snowOption: {
        modules: {
          toolbar: `#${this.id}`,
        },
        // theme: 'snow',
      },
      disabled: this.isReadonly,
      desc: this.description,
      toolbarId: this.id,
    }
  },
  watch: {
    isReadonly() {
      this.disabled = this.isReadonly
    },
    description() {
      this.desc = this.description
    },
    desc() {
      this.sendDesc()
    },
  },
  methods: {
    sendDesc() {
      return this.$emit('assign', this.desc)
    },
  },
}
</script>

<style lang="scss" >
@import '../../@core/scss/vue/libs/vue-select';
.hide-toolbar {
  .ql-toolbar{
    display: none;
  }
}
.ql-disabled{
  border: 1px solid #ccc!important;
  border-radius: 0.358rem;
}
.quill-custom{
  border-radius: 0.358rem!important;
}
.ql-toolbar{
  border-top-left-radius:0.358rem!important;
  border-top-right-radius: 0.358rem!important;
  // the dropdown picker positioning
  span {
    right:0!important;
  }
}
.ql-container{
  border-bottom-left-radius:0.358rem!important;
  border-bottom-right-radius: 0.358rem!important;
  // the link box positioning
  div {
    right:0!important;
    left: 0!important;
  }
}
.ql-toolbar-edit{
  border-bottom: 0px!important;
}
.btn-edit{
  div{
    padding: 5px;
  }
}

</style>
