<template>
  <div>
    <b-modal
      id="modal-import-csv"
      centered
      :title="title"
      size="lg"
      :hide-footer="showFormOrShowImportMessage"
      style="min-height: 1000px"
      ok-only
      ok-variant="outline-secondary"
      ok-title="Cancel"
      @ok="cancelImport"
    >
      <div v-if="showFormOrShowImportMessage">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Csv Text -->
            <validation-provider
              #default="validationContext"
              name="CSV Text"
              rules="required|verify_csv_text|verify_csv_text_length"
            >
              <b-form-group
                label="Paste CSV file cells here"
                label-for="csv-text"
              >
                <div
                  class="text-area-highlights-container"
                >
                  <div
                    ref="backdrop"
                    class="backdrop"
                  >
                    <div
                      ref="highlights"
                      class="highlights"
                    >
                      <!-- cloned text with <mark> tags here -->
                    </div>
                  </div>
                  <b-form-textarea
                    id="csv-text"
                    ref="csv-text-area"
                    v-model="form.csvText"
                    style="background-color: transparent; height: 200px "
                    placeholder="Paste CSV here"
                    :state="getValidationState(validationContext)"
                    rows="5"
                    trim
                    @input="handleCsvTextAreaInput"
                    @scroll="handleCsvTextAreaScroll"
                  />
                </div>

                <b-form-invalid-feedback style="display: block !important;">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Csv File -->
            <validation-provider
              #default="validationContext"
              name="CSV File"
            >
              <b-form-group
                label="Or select CSV file"
                label-for="csv-file"
              >
                <b-form-file
                  id="csv-file"
                  v-model="form.csvFile"
                  placeholder="Choose a csv file or drop it here..."
                  drop-placeholder="Drop file here..."
                  autofocus
                  accept=".csv"
                  :state="getValidationState(validationContext)"
                  trim
                  @input="onFileChanged($event)"
                />

                <b-card-text
                  class="my-1"
                  style="font-size: smaller"
                >
                  Selected file: <strong>{{ form.csvFile ? form.csvFile.name : '' }}</strong>
                </b-card-text>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Import
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hideImportCsvModal()"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div v-else>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
          style="height: 380px"
        >
          <div>
            <b-spinner
              class="mb-1"
              variant="primary"
            />
          </div>
          <span>Preparing import...</span>
        </div>

      </div>
    </b-modal>
    <ImportResultModal />
  </div>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInvalidFeedback,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormFile,
  BCardText,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import ImportResultModal from '@/views/shared/ImportResultModal.vue'
import {
  getCsvHeaderColumns, userEntityRequiredColumns, roleEntityRequiredColumns, processEntityRequiredColumns1, processEntityRequiredColumns2,
} from '@/utils/parse-csv'

export default {
  components: {
    BModal,
    BForm,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardText,
    BSpinner,
    ImportResultModal,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    entity: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      form: this.getDefaultForm(),
      userEntityRequiredColumns: ['name', 'handle'],
      // if this state is set to true, it's going to show import form. If it is set to false, it's going to show the import message.
      showFormOrShowImportMessage: true,
    }
  },
  created() {
    extend('verify_csv_text', {
      // Custom validation message
      message: () => {
        let requiredColumns
        if (this.entity === 'user') {
          requiredColumns = userEntityRequiredColumns
        } else if (this.entity === 'role') {
          requiredColumns = roleEntityRequiredColumns
        } else {
          return `The csv text header is invalid.\n A valid csv header must contain the required columns ${processEntityRequiredColumns1.join(' and ')} or ${processEntityRequiredColumns2.join(' and ')}.`
        }
        return `The csv text header is invalid.\n A valid csv header must contain the required columns ${requiredColumns.join(' and ')}.`
      },
      // Custom validation rule
      validate: value => new Promise(resolve => {
        const csvHeaderColumns = getCsvHeaderColumns(value)
        let result
        if (this.entity === 'user') {
          result = userEntityRequiredColumns.every(i => csvHeaderColumns.includes(i))
        } else if (this.entity === 'role') {
          result = roleEntityRequiredColumns.every(i => csvHeaderColumns.includes(i))
        } else {
          result = processEntityRequiredColumns1.every(i => csvHeaderColumns.includes(i)) || processEntityRequiredColumns2.every(i => csvHeaderColumns.includes(i))
        }
        resolve({
          valid: value && result,
        })
      }),
    })

    extend('verify_csv_text_length', {
      // Custom validation message
      message: () => 'The csv text must contain at maximum 1000 lines.',
      // Custom validation rule
      validate: value => new Promise(resolve => {
        const numberOfLines = value.split('\n').length
        resolve({
          valid: value && numberOfLines < 1001,
        })
      }),
    })
  },
  methods: {
    ...mapActions('user', [
      'cancelUsersImport',
    ]),
    ...mapActions('role', ['cancelRolesImport']),
    ...mapActions('process', ['cancelProcessesImport']),
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    hideImportCsvModal() {
      this.resetForm()
      this.$nextTick(() => {
        this.$bvModal.hide('modal-import-csv')
      })
    },
    async onFileChanged(e) {
      this.form.csvText = await this.getFileContent(e)
    },
    getFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = function onLoad(event) {
          resolve(event.target.result)
        }
        reader.onerror = function onError(error) {
          reject(error)
        }
        reader.readAsText(file, 'UTF-8')
      })
    },

    onSubmit() {
      let action
      if (this.entity === 'user') action = this.$store.dispatch('user/importUsers', this.form.csvText)
      else if (this.entity === 'role') action = this.$store.dispatch('role/importRoles', this.form.csvText)
      else if (this.entity === 'process') action = this.$store.dispatch('process/importProcesses', this.form.csvText)
      if (action) {
        this.showFormOrShowImportMessage = false
        action.then(() => {
          this.showFormOrShowImportMessage = true
          this.$bvModal.hide('modal-import-csv')
          this.resetForm()
          this.$emit('import-success')
        })
          .catch(() => {
            this.showFormOrShowImportMessage = true
          })
      }
    },
    resetForm() {
      this.$nextTick(() => {
        if (this.$refs.refFormObserver) {
          this.$refs.refFormObserver.reset()
        }
      })
      this.form = this.getDefaultForm()
    },
    getDefaultForm() {
      return {
        csvText: null,
        csvFile: null,
      }
    },
    handleCsvTextAreaInput() {
      const text = this.form.csvText
      const highlightedText = this.applyHighlights(text)
      this.$refs.highlights.innerHTML = highlightedText
    },
    handleCsvTextAreaScroll() {
      const { scrollTop } = this.$refs['csv-text-area'].$el
      this.$refs.backdrop.scrollTo(0, scrollTop)
      this.$refs.highlights.scrollTo(0, scrollTop)
    },
    applyHighlights(text) {
      const validatedBackgroundColor = 'background-color: #72e3a4'
      const errorBackgroundColor = 'background-color: #f5afaf'
      const csvHeaderColumns = getCsvHeaderColumns(text)
      const inputtedCsvHeader = text.substr(0, text.indexOf('\n'))
      let result = true
      if (this.entity === 'user') {
        result = userEntityRequiredColumns.every(i => csvHeaderColumns.includes(i))
      } else if (this.entity === 'role') {
        result = roleEntityRequiredColumns.every(i => csvHeaderColumns.includes(i))
      } else {
        result = processEntityRequiredColumns1.every(i => csvHeaderColumns.includes(i)) || processEntityRequiredColumns2.every(i => csvHeaderColumns.includes(i))
      }
      return text
        .replace(/\n$/g, '\n\n')
        .replace(inputtedCsvHeader, `<mark style="padding: 0; ${result ? validatedBackgroundColor : errorBackgroundColor}">${inputtedCsvHeader}</mark>`)
    },
    cancelImport(e) {
      e.preventDefault()
      if (this.entity === 'user') this.cancelUsersImport()
      else if (this.entity === 'role') this.cancelRolesImport()
      else if (this.entity === 'process') this.cancelProcessesImport()
    },
  },

}
</script>

<style lang="scss" scoped>
.backdrop {
  overflow: auto;
  background-color: transparent;
  position: absolute;
  padding: 0.8rem 1rem !important;
  border: 1px solid transparent;
  border-radius: 0.357rem;
  pointer-events: none;
  height: 200px;
  overflow-y: hidden;
}

.highlights {
  color: transparent;
  white-space: pre-wrap;
  word-wrap: break-word;
  line-height: 1.6rem;
}

mark {
  color: transparent;

  .error{
    background-color: #ff6766;
  }

  .validated{
    background-color: #96c29c;
  }
}
</style>
