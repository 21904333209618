<template>
  <div style="flex-grow: 1">
    <b-input-group>
      <feather-icon
        v-b-tooltip.hover.top="'Perform Search'"
        icon="SearchIcon"
        size="18"
        style="position: absolute; left: 15px; top: 10px; z-index: 0"
        @click="performSearch()"
      />

      <v-select
        id="mySelect"
        ref="filter"
        class="filter-select"
        style="flex-grow: 100;"
        :components="{openIndicator}"
        :value="selectedQueries"
        multiple
        :placeholder="searchText"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="suggestions"
        :clearable="true"
        :close-on-select="closeOnSelect"
        @option:selecting="onSelectingFilteringOption"
        @option:deselecting="onDeselectingFilteringOption"
        @search="onSearchingFilteringOption"
        @close="onCloseDropDown"
        @keydown.native="onKeyDownWhileFiltering"
      >
        <!-- Option Slot -->
        <template #option="{ label, functionality, operation, name }">

          <div
            v-if="functionality === 'search'"
            class="d-flex align-items-center"
          >
            <feather-icon
              size="18"
              icon="SearchIcon"
              class="mr-1"
            />
            <div class="detail">
              <b-card-text class="mb-0">
                {{ label }} : Search for this text
              </b-card-text>
            </div>
          </div>
          <div
            v-else-if="functionality === 'operator'"
            class="d-flex align-items-center"
          >
            <feather-icon
              size="18"
              icon="TagIcon"
              class="mr-1"
            />
            <div class="detail">
              <b-card-text
                v-if="selectedField"
                class="mb-0"
              >
                {{ label }} <code> {{ selectedField.value }}{{ operation }}value</code>
              </b-card-text>
            </div>
          </div>
          <div
            v-else-if="selectedOperator && selectedField.functionality === 'relation'"
            class="d-flex align-items-center"
          >
            <feather-icon
              size="18"
              icon="TagIcon"
              class="mr-1"
            />
            <div class="detail">
              <b-card-text class="mb-0">
                {{ name }} <code> {{ selectedField.value }}{{ selectedOperator.operation }}{{ name }}</code>
              </b-card-text>
            </div>
          </div>
          <div
            v-else-if="selectedOperator"
            class="d-flex align-items-center"
          >
            <feather-icon
              size="18"
              icon="TagIcon"
              class="mr-1"
            />
            <div class="detail">
              <b-card-text class="mb-0">
                {{ label }} <code> {{ selectedField.value }}{{ selectedOperator.operation }}{{ label }}</code>
              </b-card-text>
            </div>
          </div>
          <div
            v-else-if="functionality === 'default'"
            class="d-flex align-items-center"
            @mousedown="onMouseDownDefaultSuggestion"
          >
            <feather-icon
              size="18"
              icon="TagIcon"
              class="mr-1"
            />
            <div class="detail">
              <b-card-text class="mb-0">
                {{ label }} <code>{{ label }}</code>
              </b-card-text>
            </div>
          </div>
          <div
            v-else
            class="d-flex align-items-center"
          >
            <feather-icon
              size="18"
              icon="TagIcon"
              class="mr-1"
            />
            <div class="detail">
              <b-card-text class="mb-0">
                {{ label }}
              </b-card-text>
            </div>
          </div>
        </template>

        <!-- Selected Option Slot -->
        <template #selected-option="filterQuery">
          <div style="display: flex; align-items: baseline">
            {{ filterQuery.label }}
          </div>
        </template>

        <!-- List Footer Slot -->
        <template
          v-if="loadingSuggestionResults"
          #list-footer
        >
          <b-card-text style="margin: -5px 10px 10px 20px">
            <b-spinner
              small
              class="mr-1"
              variant="primary"
            />
            Please wait while we are fetching the results!
          </b-card-text>
        </template>

        <!-- No Option Slot-->
        <span
          v-if="loadingSuggestionResults"
          slot="no-options"
        />

      </v-select>

      <b-dropdown
        toggle-class="text-decoration-none"
        text="Saved Filters"
        variant="link"
        style="position: absolute; right: 0;"
        no-caret
        right
      >
        <!-- Button Content -->
        <template #button-content>
          <feather-icon
            v-b-tooltip.hover.top="'Save Or Apply Filter(s)'"
            icon="SlidersIcon"
            size="18"
            style="position: absolute; right: 15px; top: 10px;"
          />
        </template>

        <template>
          <b-dropdown-item @click="onManageFilters">
            Manage Filters
          </b-dropdown-item>
          <b-dropdown-item @click="openSaveFilterModal()">
            Save this search as a filter
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-group
            v-if="entity === 'task'"
            header="Date Filter"
            class="filter-dropdown-group filter-date"
          >
            <b-dropdown-item @click="openFilterByDateModal">
              Filter By Date
            </b-dropdown-item>
          </b-dropdown-group>
          <b-dropdown-divider />
          <b-dropdown-group
            header="Custom Filters"
            class="filter-dropdown-group"
          >
            <div
              v-if="filtersAccessibleToCurrentUser.nodes.length !== 0"
              class="filter-list-container"
            >
              <b-dropdown-item
                v-for="filter in filtersAccessibleToCurrentUser.nodes"
                :key="filter.id"
                @click="$emit('update:selected-queries', filter.criteria); $emit('update:search-query', filter.criteria)"
              >
                {{ filter.name }}
              </b-dropdown-item>
            </div>
            <div v-else>
              <b-dropdown-item>No available filters.</b-dropdown-item>
            </div>
          </b-dropdown-group>
          <b-dropdown-group
            v-if="entity === 'task'"
            header="Default Filters"
            class="filter-dropdown-group"
          >
            <b-dropdown-item
              v-for="defaultFilter in TASK_DEFAULT_FILTERS"
              :key="defaultFilter.name"
              @click="applyDefaultFilter(defaultFilter)"
            >
              {{ defaultFilter.name }}
            </b-dropdown-item>
          </b-dropdown-group>
        </template>

      </b-dropdown>

      <div
        v-if="isUserSearchingForValue || selectedQueries.length !== 0"
        style="position: absolute; right: 40px; top: 8px; z-index: 100"
        @click="clearSearch"
      >
        <feather-icon
          v-b-tooltip.hover.top="'Clear Search'"
          icon="XIcon"
          size="18"
        />
      </div>
    </b-input-group>
    <filter-add-modal
      :criteria="selectedQueries"
      :associated-entity="capitalizedEntityName"
    />
  </div>
</template>

<script>
import {
  BCardText,
  BInputGroup,
  BSpinner,
  BDropdownItem,
  BDropdownDivider,
  BDropdown,
  BDropdownGroup,

  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import FilterAddModal from '@/views/filter/FilterAddModal.vue'
import { OPERATOR_SUGGESTIONS, TASK_DEFAULT_FILTERS, TASK_STATUS_VALUE_SUGGESTIONS } from '@/utils/entity-filter'

export default {
  components: {
    // Custom Components
    FilterAddModal,

    // Bootstrap Components
    BSpinner,
    BCardText,
    BDropdownItem,
    BDropdownDivider,
    BDropdown,
    BInputGroup,
    BDropdownGroup,

    // Other Components
    vSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  // model: {
  //  prop: 'searchQuery',
  //  event: 'update:search-query',
  // },
  props: {
    entity: {
      required: true,
      type: String,
    },
    entityPluralSlug: {
      required: true,
      type: String,
    },
    entityFieldSuggestions: {
      required: true,
      type: Array,
    },
    searchQuery: {
      type: Array,
      required: true,
      default: () => [],
      event: 'update:search-query',
    },
    selectedQueries: {
      type: Array,
      required: false,
      default: () => [],
      event: 'update:selected-queries',
    },
    taskEntityJavascriptFilteringFunctions: {
      type: Array,
      required: false,
      default: () => [],
      event: 'update:task-entity-javascript-filtering-functions',
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false,
      event: 'update: is-editing',
    },
  },
  data() {
    return {
      openIndicator: {
        render: createElement => createElement('span', { class: { toggle: true } }),
      },
      suggestions: [],
      previousQueries: [], //  an array that holds the previous queries fields, values and operators
      selectedField: null, // currently selected field
      selectedOperator: null, // currently selected operator
      selectedValue: null, // currently selected value
      selectedQueryFunctionality: null, // currently selected query type, could be default, search, field
      query: '',
      selectedQueriesToSendToBackend: [],
      isCurrentQueryComplete: true,
      customSearchText: '',
      closeOnSelect: false,
      isUserSearchingForValue: false,
      loadingSuggestionResults: false,
      OPERATOR_SUGGESTIONS,
      TASK_STATUS_VALUE_SUGGESTIONS,
      TASK_DEFAULT_FILTERS,
    }
  },
  computed: {
    ...mapState('user', [
      'users',
      'isLoading',
      'fieldValues',
    ]),
    ...mapState('process', [
      'currentUserProcesses',
    ]),
    ...mapState('task', ['tasks']),
    ...mapState('filter', ['filtersAccessibleToCurrentUser']),
    searchText() {
      return `Search all ${this.entityPluralSlug}`
    },
    getPossibleValuesForFieldFromEntityFunctionName() {
      return `getPossibleValuesForFieldFrom${this.capitalizedEntityName}Entity`
    },
    getPossibleValuesForRelationFromEntityFunctionName() {
      return `getPossibleValuesForRelationFrom${this.entity.charAt(0).toUpperCase() + this.entity.slice(1)}Entity`
    },
    capitalizedEntityName() {
      return this.entity.charAt(0).toUpperCase() + this.entity.slice(1)
    },
  },
  created() {
    this.suggestions = this.entityFieldSuggestions
    this.getFiltersAccessibleToCurrentUser({ associatedEntities: [this.capitalizedEntityName] })
    if (this.entity === 'task') {
      // setting the values for is:active filter which is selected by default for the task page
      this.selectedValue = {
        functionality: 'default', javascriptFunction: "!task.deleted && task.status !== 'Complete'", label: 'is:active', value: 'is:active',
      }
      this.previousQueries.push(this.selectedValue)
      this.selectedQueryFunctionality = 'default'
    }
  },
  methods: {
    ...mapActions('user', [
      'getUsers',
      'getAllUsers',
      'getPossibleValuesForFieldFromUserEntity',
      'getPossibleValuesForRelationFromUserEntity',
    ]),
    ...mapActions('role', [
      'getPossibleValuesForFieldFromRoleEntity',
      // 'getPossibleValuesForRelationFromRoleEntity',
    ]),
    ...mapActions('process', [
      'getPossibleValuesForFieldFromProcessEntity',
      'getCurrentUserProcesses',
      'getPossibleValuesForRelationFromProcessEntity',
    ]),
    ...mapActions('task', [
      'getPossibleValuesForFieldFromTaskEntity',
      'getPossibleValuesForRelationFromTaskEntity',
      'getTasks',
    ]),
    ...mapActions('filter', ['getFiltersAccessibleToCurrentUser']),
    onSelectingFilteringOption(e) {
      this.closeOnSelect = false
      if (e.functionality === 'search' || e.functionality === 'default') {
        this.selectedQueryFunctionality = e.functionality
        this.suggestions = [...this.entityFieldSuggestions]
        this.selectedQueries.push(e.value)
        this.selectedQueriesToSendToBackend.push(e.value)
        this.previousQueries.push(e)
        const taskEntityJavascriptFilteringFunctionsCopy = this.taskEntityJavascriptFilteringFunctions
        if (e.functionality === 'default') {
          if (e.javascriptFunction) {
            taskEntityJavascriptFilteringFunctionsCopy.push(e.javascriptFunction)
            this.$emit('update:task-entity-javascript-filtering-functions', taskEntityJavascriptFilteringFunctionsCopy)
            // this is used to not let the task-list change the date range
            this.$emit('update:is-editing', true)
            this.$emit('reset-is-editing-to-false')
          }
        } else if (this.entity === 'task') {
          taskEntityJavascriptFilteringFunctionsCopy.push(`( task.name.match(/${e.value}/i) || task.process.processHandle.match(/${e.value}/i) || task.assignee.name.match(/${e.value}/i) || task.status.replace(/ /g,'').match(/${e.value}/i) )`)
          this.$emit('update:task-entity-javascript-filtering-functions', taskEntityJavascriptFilteringFunctionsCopy)
        } else {
          this.$emit('update:search-query', [...this.selectedQueriesToSendToBackend])
        }
      } else if (!this.selectedField) { // selecting a field
        // Change the value of selected field state
        this.selectedField = e
        this.selectedQueryFunctionality = e.functionality
        this.isCurrentQueryComplete = false
        // Filter the operatorSuggestions
        let allowedOperationsSuggestions = []
        allowedOperationsSuggestions = e.allowedOperators?.map(allowedOperator => this.OPERATOR_SUGGESTIONS.find(el => el.value === allowedOperator))

        // Change the value of suggestions which are the options in the select
        this.suggestions = allowedOperationsSuggestions
        this.selectedQueries.push(this.selectedField.value)
        this.closeOnSelect = false
      } else if (!this.selectedOperator) { // selecting an operator
        this.selectedOperator = e
        this.selectedQueries.pop()
        this.selectedQueries.push(`${this.selectedField.value}${this.selectedOperator.operation}`)
        this.suggestions = []
        if (this.selectedField.functionality === 'field') {
          if (this.entity === 'task') {
            this.closeOnSelect = true

            if (this.selectedField.value === 'status') {
              this.suggestions = TASK_STATUS_VALUE_SUGGESTIONS
            } else if (this.selectedField.value === 'name') {
              this.suggestions = []
            }
          } else {
            this.loadingSuggestionResults = true
            // eslint-disable-next-line no-eval
            eval(`this.${this.getPossibleValuesForFieldFromEntityFunctionName}`)(this.selectedField.value).then(() => {
              this.loadingSuggestionResults = false
              if (this.$route.name === 'my-process-list') {
                this.getCurrentUserProcesses({
                  q: '',
                }).then(() => {
                  this.suggestions = this.currentUserProcesses.nodes.map(proc => proc[this.selectedField.value])
                })
              } else {
                this.suggestions = Array.from(new Set(this.$store.getters[`${this.entity}/fieldValues`]))
              }
              this.closeOnSelect = true
            }).catch(() => {
              this.loadingSuggestionResults = false
            })
          }
        } else { // functionality is 'relation'
          // eslint-disable-next-line no-lonely-if
          if (this.entity === 'task' && this.selectedField.value === 'assignee') {
            this.closeOnSelect = true

            this.getAllUsers().then(users => {
              const usersWithLabel = users.map(user => ({
                label: user.name,
                ...user,
              }))
              this.suggestions = usersWithLabel
            })
          } else {
            // eslint-disable-next-line no-eval
            eval(`this.${this.getPossibleValuesForRelationFromEntityFunctionName}`)(this.selectedField.value).then(() => {
              this.loadingSuggestionResults = false
              // eslint-disable-next-line arrow-body-style
              const labels = this.$store.getters[`${this.entity}/relationValues`].map(item => {
                return {
                  label: item.name,
                  ...item,
                }
              })
              const uniqueLabels = new Map(labels.map(l => ([l.id, l])))
              this.suggestions = [...uniqueLabels.values()]
              this.closeOnSelect = true
            }).catch(() => {
              this.loadingSuggestionResults = false
            })
          }
        }
      } else if (this.selectedOperator && this.selectedField.functionality === 'relation') { // selecting a relation value
        this.selectedValue = e
        this.selectedQueries.pop()
        this.selectedQueries.push(`${this.selectedField.value}${this.selectedOperator.operation}${this.selectedValue.name}`)
        this.selectedQueriesToSendToBackend.push(`${this.selectedField.value}${this.selectedOperator.operation}${this.selectedValue.id}`)
        this.suggestions = this.entityFieldSuggestions
        // update the javascript filtering functions if
        if (this.entity === 'task') {
          // const javascriptFilteringFunction = this.selectedField.javascriptFunction.replace("'javascriptOperator'", this.selectedOperator.javascriptOperator).replace("'userId'", this.selectedValue.id)
          // const taskEntityJavascriptFilteringFunctionsCopy = this.taskEntityJavascriptFilteringFunctions
          // taskEntityJavascriptFilteringFunctionsCopy.push(javascriptFilteringFunction)
          // this.$emit('update:task-entity-javascript-filtering-functions', taskEntityJavascriptFilteringFunctionsCopy)
        }
        this.isCurrentQueryComplete = true
        this.closeOnSelect = false
      } else { // selecting a value
        this.selectedValue = e
        this.selectedQueries.pop()
        this.selectedQueries.push(`${this.selectedField.value}${this.selectedOperator.operation}${this.selectedValue}`)
        this.selectedQueriesToSendToBackend.push(`${this.selectedField.value}${this.selectedOperator.operation}${this.selectedValue}`)
        this.suggestions = this.entityFieldSuggestions
        // this.previousQueries.push({ field: this.selectedField, operator: this.selectedOperator, value: this.selectedValue })

        this.isCurrentQueryComplete = true
        this.closeOnSelect = false
      }

      if (this.entity === 'process' || this.entity === 'role') {
        this.$emit('onSelect')
      }
    },
    // remove a filtering tag event
    onDeselectingFilteringOption(e) {
      const indexOfDeletedFilteringOption = this.selectedQueries.indexOf(e)
      this.previousQueries.splice(indexOfDeletedFilteringOption, 1)
      this.selectedQueries.splice(indexOfDeletedFilteringOption, 1)
      this.selectedQueriesToSendToBackend.splice(indexOfDeletedFilteringOption, 1)
      let selectedQueriesToSendToBackend = this.selectedQueriesToSendToBackend.filter(a => a !== e)
      selectedQueriesToSendToBackend = [...selectedQueriesToSendToBackend, ...this.selectedQueries]
      this.$emit('update:search-query', [...selectedQueriesToSendToBackend])
      // update the javascript filtering functions for task entity
      if (this.entity === 'task') {
        const javascriptFilteringFunctions = []
        this.previousQueries.forEach(previousQuery => {
          if (previousQuery.functionality === 'default' || previousQuery.functionality === 'relation') javascriptFilteringFunctions.push(previousQuery.javascriptFunction)
          else if (previousQuery.functionality === 'search') {
            javascriptFilteringFunctions.push(`( task.name.match(/${previousQuery.value}/i) || task.process.processHandle.match(/${previousQuery.value}/i) || task.assignee.name.match(/${previousQuery.value}/i) || task.status.replace(/ /g,'').match(/${previousQuery.value}/i) )`)
          }
        })
        this.$emit('update:task-entity-javascript-filtering-functions', javascriptFilteringFunctions)
        // this is used to not let the task-list change the date range
        this.$emit('update:is-editing', true)
        this.$emit('reset-is-editing-to-false')
        this.$emit('onClearFilter')
      }

      if (this.entity === 'process' || this.entity === 'role') {
        const q = this.$route.query

        // eslint-disable-next-line no-prototype-builtins
        if (q.hasOwnProperty('q') && q.hasOwnProperty('qd') && q.hasOwnProperty('compact')) {
          this.$emit('clear-query')
        }
      }

      // show the field suggestions
      this.suggestions = this.entityFieldSuggestions

      // resetting the values of selectedField & selectedOperator & selectedValue
      this.selectedQueryFunctionality = null
      this.selectedField = null
      this.selectedOperator = null
      this.selectedValue = null
      this.isCurrentQueryComplete = true
      this.closeOnSelect = false
    },
    onSearchingFilteringOption(search) {
      if (search && !this.selectedField) {
        this.suggestions = this.entityFieldSuggestions.concat([{ label: search, value: search, functionality: 'search' }])
      } else if (!search && !this.selectedField) {
        this.suggestions = this.entityFieldSuggestions
      }
      if (search) {
        this.isUserSearchingForValue = true
        if (!this.entityFieldSuggestions.find(el => el.value.includes(search)) && !this.OPERATOR_SUGGESTIONS.find(el => el.value.includes(search)) && !this.entityFieldSuggestions.find(el => el.value.includes(search))) {
          this.closeOnSelect = true
        } else {
          this.closeOnSelect = false
        }
      } else this.isUserSearchingForValue = false
    },
    onCreatingFilteringOption(newOption) {
      this.selectedQueries.push(newOption.label)
    },
    onCloseDropDown() {
      if (this.isCurrentQueryComplete && this.selectedQueries.length !== this.previousQueries.length) {
        if (this.selectedQueryFunctionality === 'field') {
          this.previousQueries.push({ field: this.selectedField, operator: this.selectedOperator, value: this.selectedValue })
        } else if (this.entity === 'task') {
          // this.previousQueries.push({
          //   functionality: 'relation',
          //   javascriptFunction: this.selectedField.javascriptFunction.replace("'javascriptOperator'", this.selectedOperator.javascriptOperator).replace("'userId'", this.selectedValue.id),
          //   label: `${this.selectedField.value}${this.selectedOperator.operation}${this.selectedValue.name}`,
          //   value: `${this.selectedField.value}${this.selectedOperator.operation}${this.selectedValue.id}`,
          //   field: this.selectedField,
          //   operator: this.selectedOperator,
          // })
        } else if (this.selectedValue !== null) {
          this.previousQueries.push(this.selectedValue)
        }

        this.$emit('update:search-query', [...this.selectedQueriesToSendToBackend, ...this.selectedQueries])

        // resetting the values of selectedField, selectedOperator, selectedValue
        this.selectedQueryFunctionality = null
        this.selectedField = null
        this.selectedOperator = null
        this.selectedValue = null
      }
    },
    onKeyDownWhileFiltering(e) {
      if (!this.isUserSearchingForValue) {
        if (e.code === 'Backspace') {
          if ((this.isCurrentQueryComplete && this.previousQueries.at(-1).functionality === 'search') || (this.isCurrentQueryComplete && this.previousQueries.at(-1).functionality === 'default')) {
            this.selectedQueries.pop()
            this.selectedQueriesToSendToBackend.pop()
            this.previousQueries.pop()
            this.$emit('update:selected-queries', [...this.selectedQueries])
            this.$emit('update:search-query', [...this.selectedQueriesToSendToBackend])
            if (this.entity === 'task') {
              const javascriptFilteringFunctions = []
              this.previousQueries.forEach(previousQuery => {
                if (previousQuery.functionality === 'default' || previousQuery.functionality === 'relation') javascriptFilteringFunctions.push(previousQuery.javascriptFunction)
                else if (previousQuery.functionality === 'search') {
                  javascriptFilteringFunctions.push(`( task.name.match(/${previousQuery.value}/i) || task.process.processHandle.match(/${previousQuery.value}/i) || task.assignee.name.match(/${previousQuery.value}/i) || task.status.replace(/ /g,'').match(/${previousQuery.value}/i) )`)
                }
              })
              this.$emit('update:task-entity-javascript-filtering-functions', javascriptFilteringFunctions)
            }
          } else if (this.isCurrentQueryComplete && typeof this.previousQueries.at(-1) === 'object') { // deleting a value
            this.selectedValue = null
            this.selectedField = this.previousQueries.at(-1).field
            this.selectedOperator = this.previousQueries.at(-1).operator
            this.isCurrentQueryComplete = false
            this.closeOnSelect = true
            this.selectedQueries.pop()
            this.selectedQueries.push(`${this.selectedField.value}${this.selectedOperator.operation}`)
            if (this.selectedField.functionality === 'relation') {
              if (this.entity === 'task' && this.selectedField.value === 'assignee') {
                this.closeOnSelect = true
                this.suggestions = [...this.users.nodes]
              } else {
                // eslint-disable-next-line no-eval
                eval(`this.${this.getPossibleValuesForRelationFromEntityFunctionName}`)(this.selectedField.value).then(() => {
                  this.loadingSuggestionResults = false
                  this.suggestions = [...new Map(this.$store.getters[`${this.entity}/relationValues`].map(item => [item.id, item])).values()]
                  this.closeOnSelect = true
                }).catch(() => {
                  this.loadingSuggestionResults = false
                })
              }
            } else {
              // eslint-disable-next-line no-eval
              eval(`this.${this.getPossibleValuesForFieldFromEntityFunctionName}`)(this.selectedField.value).then(() => {
                this.loadingSuggestionResults = false
                this.suggestions = Array.from(new Set(this.$store.getters[`${this.entity}/fieldValues`]))
                this.closeOnSelect = true
              }).catch(() => {
                this.loadingSuggestionResults = false
              })
            }
          } else if (this.selectedOperator) { // deleting an operator
            this.selectedOperator = null
            this.suggestions = this.OPERATOR_SUGGESTIONS
            this.closeOnSelect = false

            this.selectedQueries.pop()
            this.selectedQueries.push(`${this.selectedField.value}`)
          } else if (this.selectedField) { // deleting a field
            this.selectedQueries.pop()
            this.selectedQueriesToSendToBackend.pop()
            this.previousQueries.pop()
            this.$emit('update:selected-queries', [...this.selectedQueries])
            this.$emit('update:search-query', [...this.selectedQueriesToSendToBackend])
            if (this.entity === 'task') {
              const javascriptFilteringFunctions = []
              this.previousQueries.forEach(previousQuery => {
                if (previousQuery.functionality === 'default' || previousQuery.functionality === 'relation') javascriptFilteringFunctions.push(previousQuery.javascriptFunction)
                else if (previousQuery.functionality === 'search') {
                  javascriptFilteringFunctions.push(`( task.name.match(/${previousQuery.value}/i) || task.process.processHandle.match(/${previousQuery.value}/i) || task.assignee.name.match(/${previousQuery.value}/i) || task.status.replace(/ /g,'').match(/${previousQuery.value}/i) )`)
                }
              })
              this.$emit('update:task-entity-javascript-filtering-functions', javascriptFilteringFunctions)
            }
            this.suggestions = this.entityFieldSuggestions

            // resetting the values
            this.selectedQueryFunctionality = null
            this.selectedField = null
            this.selectedOperator = null
            this.selectedValue = null
            this.isCurrentQueryComplete = true
            this.closeOnSelect = false
          }
        }
      }
    },
    onMouseDownDefaultSuggestion() {
      this.closeOnSelect = true
    },
    applyDefaultFilter(defaultFilter) {
      this.$emit('update:selected-queries', defaultFilter.criteria)
      this.$emit('update:task-entity-javascript-filtering-functions', defaultFilter.javascriptFunctions)
    },
    performSearch() {
      if (this.$refs.filter.search !== '') {
        const searchValue = this.$refs.filter.search
        this.selectedQueries.push(searchValue)
        this.selectedQueriesToSendToBackend.push(searchValue)
        this.previousQueries.push({ functionality: 'search', label: searchValue, value: searchValue })
        this.$emit('update:search-query', [...this.selectedQueriesToSendToBackend])
        if (this.entity === 'task') {
          const taskEntityJavascriptFilteringFunctionsCopy = this.taskEntityJavascriptFilteringFunctions
          taskEntityJavascriptFilteringFunctionsCopy.push(`( task.name.match(/${searchValue}/i) || task.process.processHandle.match(/${searchValue}/i) || task.assignee.name.match(/${searchValue}/i) || task.status.replace(/ /g,'').match(/${searchValue}/i) )`)
          this.$emit('update:task-entity-javascript-filtering-functions', taskEntityJavascriptFilteringFunctionsCopy)
        }
        this.$refs.filter.search = ''
      }
    },
    // creating and saving filters
    openSaveFilterModal() {
      this.$nextTick(() => {
        this.$bvModal.show('modal-save-filter')
      })
    },

    // opening create filter modal
    openFilterByDateModal() {
      this.$nextTick(() => {
        this.$bvModal.show('modal-filter-tasks-by-date')
      })
    },
    clearSearch() {
      // the clear search must empty the inputted text not only the tags
      this.$refs.filter.search = ''
      this.selectedQueriesToSendToBackend = []
      this.$emit('update:selected-queries', [])
      this.$emit('update:search-query', [...this.selectedQueriesToSendToBackend])
      if (this.entity === 'task') {
        this.$emit('update:task-entity-javascript-filtering-functions', [])
      }
    },
    onManageFilters() {
      let entity = ''
      if (this.entity === 'task') {
        entity = 'Task'
      } else if (this.entity === 'role') {
        entity = 'Role'
      } else if (this.entity === 'process') {
        entity = 'Process'
      } else if (this.entity === 'user') {
        entity = 'User'
      }
      this.$router.push({ path: '/filters', query: { entity } })
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-select::v-deep {
  .vs__selected-options{
    padding-left: 40px;
  }
}

.filter-list-container::-webkit-scrollbar {
  width: 8px;
}

.filter-list-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.filter-list-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.filter-list-container{
  max-height: 200px;
  overflow-y: scroll;
}

.filter-dropdown-group::v-deep{
  header{
    font-size: x-small;
    color: #9ca0a4!important
  }
}

.filter-date::v-deep{
  a {
    background-color: #193a9a !important;
    color: white;
  }
}
</style>

<style lang="scss">
  @import '../../@core/scss/vue/libs/vue-select';
  .vs__deselect{
    svg{
      filter: grayscale(30%) sepia(50%);
    }
  }
</style>
