export const USER_ENTITY_FIELD_SUGGESTIONS = [
  {
    label: 'Name', value: 'name', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
  {
    label: 'Handle', value: 'peopleHandle', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
  {
    label: 'User Type', value: 'userType', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
  {
    label: 'Authentication Method', value: 'authMethod', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
  {
    label: 'Role', value: 'roles', functionality: 'relation', allowedOperators: ['equal', 'notEqual'],
  },
]

export const ROLE_ENTITY_FIELD_SUGGESTIONS = [
  {
    label: 'Name', value: 'name', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
  {
    label: 'Handle', value: 'roleHandle', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
]

export const PROCESS_ENTITY_FIELD_SUGGESTIONS = [
  {
    label: 'Name', value: 'name', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
  {
    label: 'Handle', value: 'processHandle', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  },
  {
    label: 'Label', value: 'labels', functionality: 'relation', allowedOperators: ['equal', 'notEqual'],
  },
]

export const OPERATOR_SUGGESTIONS = [
  {
    label: 'Equal', value: 'equal', operation: '=', functionality: 'operator', javascriptOperator: '==',
  },
  {
    label: 'Not Equal', value: 'notEqual', operation: '!=', functionality: 'operator', javascriptOperator: '!=',
  },
  {
    label: 'Has', value: 'has', functionality: 'operator',
  },
]

export const TASK_ENTITY_FIELD_SUGGESTIONS = [
  {
    label: 'all:tasks', value: 'all:tasks', functionality: 'default', javascriptFunction: '',
  },
  {
    label: 'is:active', value: 'is:active', functionality: 'default', javascriptFunction: "!task.deleted && task.status !== 'Complete'",
  },
  {
    label: 'is:late', value: 'is:late', functionality: 'default', javascriptFunction: "moment__WEBPACK_IMPORTED_MODULE_22__(task.dueDate).diff(Date.now(), 'days') < 0 && task.status != 'Complete'",
  },
  {
    label: 'is:complete', value: 'is:complete', functionality: 'default', javascriptFunction: "task.status == 'Complete'",
  },
  {
    label: 'is:deleted', value: 'is:deleted', functionality: 'default', javascriptFunction: 'task.deleted == true',
  },
  {
    label: 'isNot:complete', value: 'isNot:complete', functionality: 'default', javascriptFunction: "task.status != 'Complete'",
  },
  {
    label: 'isNot:deleted', value: 'isNot:deleted', functionality: 'default', javascriptFunction: 'task.deleted != true',
  },
  // {
  //  label: 'status', value: 'status', functionality: 'field', allowedOperators: ['equal', 'notEqual'],
  // },
  {
    label: 'Assignee', value: 'assignee', functionality: 'relation', allowedOperators: ['equal', 'notEqual'],
  },
]

export const TASK_STATUS_VALUE_SUGGESTIONS = ['Not Started', 'In Progress', 'Complete']

export const TASK_DEFAULT_FILTERS = [
  { name: 'All Tasks', criteria: ['all:tasks'], javascriptFunctions: [] },
  { name: 'Active Tasks', criteria: ['is:active'], javascriptFunctions: ["!task.deleted && task.status !== 'Complete'"] },
  { name: 'Late Tasks', criteria: ['is:late'], javascriptFunctions: ["moment__WEBPACK_IMPORTED_MODULE_22__(task.dueDate).diff(Date.now(), 'days') < 0 && task.status != 'Complete'"] },
  { name: 'Complete Tasks', criteria: ['is:complete'], javascriptFunctions: ["task.status == 'Complete'"] },
  { name: 'Deleted Tasks', criteria: ['is:deleted'], javascriptFunctions: ['task.deleted == true'] },
]
